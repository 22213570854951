thead td {
  @apply font-semibold text-gray-500;
}

table {
  @apply border-separate border-spacing-0 overflow-hidden rounded-[10px];
}

table tbody td {
  @apply text-gray-600;
}

table tbody tr:last-child td {
  @apply shadow-none;
}
